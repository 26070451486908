import React from "react";
import { Container } from "react-bootstrap";

export default function ServiceTitle() {
  return (
    <div className="serviceTitle-main">
      <Container fluid className="text-center">
        <p>Our Services</p>
        <h2>RS Gaur Global Hospital offers top of the line <br></br> comprehensive care and treatments in department of</h2>
      </Container>
    </div>
  );
}
