import React from "react";
// import logo from "../assets/donation.png";
import logo1 from "../assets/facebook.png";
import logo2 from "../assets/whatsapp.png";
export default function Donationbanner() {
  return (
    <> <a target={"_blank"} href="https://www.facebook.com/GGMSHJhajjar">
    <img className="donation" src={logo1} />{" "}
  </a>
     <a target={"_blank"} href= "https://wa.me/919034224348?text=Hi!%20Can%20you%20please%20help%20me%20with%20">
        <img className="whatsapp" src={logo2} />{" "}
      </a>
     
      {/* target={"_blank"}  */}
      {/* <a href= "/donation"> 
        <img className="donation1"
        
        // style={{ width:200 }}
        src={logo} />{" "}
      </a> */}
    </>
  );
}
