import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function AboutTitle() {
  return (
    <div className="aboutTitle-main">
      <Container fluid className="text-center">
        <p>About Us</p>
        <h2>About Us</h2>
      </Container>
      <Container>
        <Row>
          <Col sm={4}>
            <h2>Personal care for your healthy living</h2>
          </Col>
          <Col sm={8}>
            <p>
            RS Gaur Global Multispeciality Hospital Pvt Ltd at Jhajjar  is 51 bed multispeciality hospital aims to provide integrated medical care to a wider range of patient population with its state-of-art infrastructure and unparalleled medical facilities. With an objective to enhance the experience of thirteen glorious years of running a diagnostic center, Global group decided to bring in world-class medical expertise in the northern part of the country through this well equipped multi speciality healthcare centre with primary objective of quality, transparency and patient safety. RS Gaur Global Multispeciality Hospital Pvt Ltd is an 51 bed hospital beautifully landscaped , It has Suite, Super-deluxe, single & multi-occupancy patient rooms, 2 operating theatres, 20-bedded  Intensive Care Unit, 05 -bed Neonatal Intensive Care Unit, a laboratory where a wide array of tests can be carried out round the clock along with a cutting-edge diagnostic imaging center and integrated pharmacy outlet. With expertise and excellence being the cornerstones of RS Gaur Global Multispeciality Hospital Pvt Ltd, the hospital not only aims to furnish the very best in medical services but also intends to play a significant role in advancing the healthcare infrastructure and overall macroeconomic development of Jhajjar & North India.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row xs={1} md={2} lg={4} className="g-4">
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1551884170-09fb70a3a2ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Healthcare for Kids
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1564732005956-20420ebdab60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Medical Counseling
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1551884170-09fb70a3a2ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Modern Equipments
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1564732005956-20420ebdab60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fGhvc3BpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                className="mb-3"
              />
              <Card.Body className="p-0">
                <Card.Title className="fs-5 fw-semibold">
                  Qualified Doctors
                </Card.Title>
                <Card.Text className="fs-6">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={4} className="d-flex flex-column justify-content-center">
            <h2>Our Doctors achievements</h2>
            <div className="line"></div>
          </Col>
          <Col sm={8}>
            <Row xs={1} md={2} lg={3} className="g-2 text-center mt-4 mt-sm-0">
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
              <Col>
                <img
                  src="https://images.unsplash.com/photo-1558983467-5dd0595a81a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGJyYW5kJTIwbG9nb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  style={{ width: "200px", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
