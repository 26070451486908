import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/logo.png";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { ImLocation } from "react-icons/im";
import { FaInstagram, FaWhatsapp, FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-main">
      <Container>
        <Row xs={1} md={2} lg={4} className="g-3">
          <Col className="footA">
            <h4 className="mb-3">RS GAUR GLOBAL MULTISPECIALITY HOSPITAL</h4>
            <p>
              <FiPhoneCall />{" "}
              <a href="tel:+919034224348" className="text-dark text-decoration-none" target="_blank" rel="noreferrer">
                +91 9034224348
              </a><br/> <a href="tel:+919518449253" className="text-dark text-decoration-none" target="_blank" rel="noreferrer">
                +91 9518449253
              </a>
            </p>
            <p>
              <FiMail />{" "}
              <a href="mailto:globalmshospital@gmail.com" className="text-dark text-decoration-none">
              globalmshospital@gmail.com
              </a>
            </p>
            <p>
              <ImLocation /> Hospital in D-2 Subhash Nagar, Jhajjar Near Civil
              Hospital
            </p>
            <a href="https://www.facebook.com/RSgaurGlobalhospital/?ref=aymt_homepage_panel&eid" target="_blank" rel="noreferrer">
              <FaFacebook color="#000" size={26} />
            </a>{" "}
            &nbsp; &nbsp;
            <a href="/">
              <FaInstagram color="#000" size={26} />
            </a>{" "}
            &nbsp; &nbsp;
            <a href="https://wa.me/919034224348?text=Hi!%20Can%20you%20please%20help%20me%20with%20" target="_blank" rel="noreferrer">
              <FaWhatsapp color="#000" size={26} />
            </a>
            &nbsp; &nbsp;
            <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
              <FaYoutube color="#000" size={26} />
            </a>
            &nbsp; &nbsp;
            <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
              <FaTwitter color="#000" size={26} />
            </a>
          </Col>
          <Col className="footB">
            <ul className="list-unstyled">
              <li>
                <p>
                  <Link
                    to="/services"
                    className="text-black text-decoration-none text-center text-md-start"
                  >
                    Service
                  </Link>
                </p>
              </li>
              <li>
                <p>
                  <Link
                    to="/about"
                    className="text-black text-decoration-none text-center text-md-start"
                  >
                    About Us
                  </Link>
                </p>
              </li>
              <li>
                <p>
                  <Link
                    to="/gallery"
                    className="text-black text-decoration-none text-center text-md-start"
                  >
                    Gallery
                  </Link>
                </p>
              </li>
            </ul>
          </Col>
          <Col>
            <ul className="list-unstyled">
              <li>
                <p>
                  <Link
                    to="/doctors"
                    className="text-black text-decoration-none text-center text-md-start"
                  >
                    Our Team
                  </Link>
                </p>
              </li>
              <li>
                <p>
                  <Link
                    to="/contact"
                    className="text-black text-decoration-none text-center text-md-start"
                  >
                    Contact Us
                  </Link>
                </p>
              </li>
              <li>
                <p>
                  <Link
                    to="/blogs"
                    className="text-black text-decoration-none text-center text-md-start"
                  >
                    Blogs
                  </Link>
                </p>
              </li>
            </ul>
          </Col>
          <Col className="d-none d-lg-flex align-items-center">
            <img src={logo} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
