import React from "react";
import { Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import hospital from "../assets/hospital.jpg";

export default function PersonalCare() {
  return (
    <div className="personalCare-main">
      <Container>
        <Row>
          <div className="col-md-6">
            <img src={hospital} alt="" style={{ maxWidth: "inherit" }} />
          </div>
          <div className="col-md-6 d-flex align-items-center mt-3 mt-md-0">
            <div style={{maxWidth:"350px"}}>
            <h2>Our hospital is first full 5th standard NABH accredited hospital in Jhajjar</h2>
            <p>
              We are empanelled with ECHS, Haryana govt., Ayushman Bharat
              And with almost  all major private insurance company and TPA
            </p>
            <Link
              to="/services"
              className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
              style={{ width: "200px" }}
            >
              <span>Services</span>
              <AiOutlineRight className="mt-1" />
            </Link>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}
