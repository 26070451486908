import React from "react";
import Footer from "./Footer";
import NavbarTop from "./Navbar";
import Donationbanner from "./donationbanner";
import { Container } from "react-bootstrap";
import imgcareer from "../assets/career.jpg"
export default function Career() {
  return (
    <div>
      <Donationbanner />
      <NavbarTop />
      <div className="contactTitle">
        <Container fluid className="text-center">
          <p>Career</p>
          <h2>Join Us Today</h2>
        </Container>
      </div>
      <div className="career">
    <div>    <p className="p2">Welcome to Careers at RS Gaur Global Hospital</p>

        <div className="containercareer">
          <p className="p1">
            We believe that the success of our hospital is driven by our
            dedicated team of healthcare professionals. As an organization, we
            are committed to attracting, developing, and retaining top talent.
            We offer a wide range of career opportunities across various
            functions, including nursing, medical, administration, operations,
            and more. At RS Gaur Global Hospital, we are committed to providing our
            employees with a supportive and inclusive work environment that
            fosters personal and professional growth. We believe in investing in
            our people and providing them with opportunities to enhance their
            skills and knowledge through ongoing training and development
            programs. Our employees enjoy a comprehensive benefits package that
            includes competitive compensation, health and wellness programs,
            retirement savings, and other benefits that help them achieve their
            personal and professional goals. If you are passionate about making
            a difference in the lives of patients and their families, we invite
            you to explore career opportunities with RS Gaur Global Hospital. We are
            always looking for talented individuals who share our commitment to
            providing compassionate care and exceptional service. To learn more
            about our current career opportunities, please visit our job board
            or submit your resume to our HR team. <br/>Thank you for considering
            RS Gaur Global Hospital as your employer of choice.
            <br/><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfuiaqfqTUjS4Liai0Mpx_hP0tMgxY_a-fhRf_w0NKAusGnUA/viewform?usp=sf_link">You can apply from here.</a>

          </p>
          <img src={imgcareer}/>
        </div>

<div >


</div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
