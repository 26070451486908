import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import first from "../assets/1.jpeg";
import second from "../assets/2.jpeg";
import third from "../assets/3.jpeg";
import fourth from "../assets/4.jpeg";
import fifth from "../assets/5.jpeg";
import sixth from "../assets/6.jpeg";
import seventh from "../assets/7.jpeg";
import eighth from "../assets/8.jpeg";
import ninth from "../assets/9.jpeg";
import ten from "../assets/10.jpg";
import eleven from "../assets/11.jpg";
import twelev from "../assets/12.jpg";

export default function DoctorsBody() {
  return (
    <div className="serviceTitle-main">
      <Container fluid className="text-center">
        <p>All Doctors</p>
        <h2>Specialized Doctors</h2>
      </Container>

      <div className="d-flex flex-column align-items-center text-center justify-content-center mt-5">
        <h2 className="fw-bold" style={{ color: "#233D6B" }}>
          Doctors
        </h2>
        <div className="line my-4"></div>
        <p style={{ maxWidth: "500px" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
          cumque, rerum voluptates magnam quo autem totam.
        </p>
      </div>

      <Container className="doctors-grid">
        <Row
          xs={1}
          sm={2}
          md={3}
          lg={4}
          className="g-4"
          style={{ padding: "100px 0" }}
        >
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={first} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Rohan</Card.Title>
                <Card.Text>
                  MBBS, DNB(Ortho)
                  <br />
                  Consultant orthopaedic surgeon
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={second} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Parul</Card.Title>
                <Card.Text>
                  MBBS, MD
                  <br />
                  Consultant Pathologist
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={third} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Vimal Shankhdhar</Card.Title>
                <Card.Text>Director</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={fourth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Nisha</Card.Title>
                <Card.Text>
                  MBBS, MD(Med)
                  <br />
                  Consultant Physician
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={fifth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Neha</Card.Title>
                <Card.Text>
                  BDS (Dental Surgeon)
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={sixth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Surender Gaur</Card.Title>
                <Card.Text>
                  MBBS, PGDCC, FIC
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={seventh} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Suraina Gaur</Card.Title>
                <Card.Text>
                  MBBS, (Medical Officer)
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={eighth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Renu Gaur</Card.Title>
                <Card.Text>
                  Medical Superintendent
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={ninth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Akash Sharma</Card.Title>
                <Card.Text>
                  MBBS, (Medical Officer)
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={ten} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Mohd. Junaid Ansar</Card.Title>
                <Card.Text>
                MBBS, MS, DNB, MNAMS,
                General, Laparoscopic & Laser Surgeon 
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col><Col>
            <Card className="border-0">
              <Card.Img variant="top" src={eleven} />
              <Card.Body className="px-0">
                <Card.Title>Dr.Ravinder Kumar</Card.Title>
                <Card.Text>
                  MBBS, MD, Medicine
                  Fellowship in Clinical Medicine
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col><Col>
            <Card className="border-0">
              <Card.Img variant="top" src={twelev} />
              <Card.Body className="px-0">
                <Card.Title>Dr.Shehla Naseem</Card.Title>
                <Card.Text>
                MBBS, MD,
                  Anesthesia, Critical Container
                  & Pain Medicine
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid className="serviceBottom">
        <Container>
          <div className="line"></div>
          <h2 className="text-dark fw-bolder">
            We are pleased to offer you the <br />
            <span style={{ color: "#223A66" }}>chance to have the healthy</span>
          </h2>
          <Link
            to="/contact"
            className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
            style={{ width: "fit-content" }}
          >
            <span>GET APPOINTMENT</span>&nbsp;&nbsp;
            <AiOutlineRight className="mt-1" />
          </Link>
        </Container>
      </Container>
    </div>
  );
}
