import React from 'react'
import AboutSpecialist from './AboutSpecialist'
import AboutTitle from './AboutTitle'
import Footer from './Footer'
import NavbarTop from './Navbar'
import Donationbanner from './donationbanner'
export default function AboutPage() {
  return (
    <div>
           <Donationbanner/>
        <NavbarTop/>
        <AboutTitle/>
        <AboutSpecialist/>
        <Footer/>
    </div>
  )
}
