import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { HiDocumentPlus, HiOutlineClock } from "react-icons/hi2";
import { RiCustomerService2Fill } from "react-icons/ri";

export default function Cards() {
  return (
    <div className="cards-main">
      <Container>
        <Row xs={1} md={2} xl={3} className="g-4">
          <Col>
            <Card className="shadow-lg">
              <Card.Body>
                <HiDocumentPlus className="mb-2" size={56} color="#000" />
                <br />
                <p>24 Hours Service</p>
                <h3>Digital Reports</h3>
                <Card.Text>
                  Get All reports online 24x7 using your phone number or
                  email-id
                </Card.Text>
                <Button variant="primary" className="rounded-pill">
                  Download Your Report
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="shadow-lg">
              <Card.Body>
                <HiOutlineClock className="mb-2" size={56} color="#000" />
                <br />
                <p>Timing Schedule</p>
                <h3>Working Hours</h3>
                <div>
                  {/* <div className="d-flex justify-content-between">
                    <span>Sunday:</span>
                    <span>12:00 AM - 12:00 PM</span>
                  </div> */}
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span>IPD & Emergency Timings:</span>
                    <span>24x7 &nbsp;All&nbsp; 365 Days</span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span>OPD Timing:</span>
                    <span>09:00 AM - 06:00 PM</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="shadow-lg">
              <Card.Body>
                <RiCustomerService2Fill
                  className="mb-2"
                  size={56}
                  color="#000"
                />
                <br />
                <p>Emergency Cases</p>
                <h3><a className="text-dark text-decoration-none" href="tel:01251255565">01251255565</a></h3>
                <h3><a className="text-dark text-decoration-none" href="tel:9518449253">9518449253</a></h3>
                <Card.Text>
                  Get All time support for emergency. We have introduced the
                  principle of family medicine.Get Connected with us for any
                  urgency.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
