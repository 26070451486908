import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import sanityClient from "../sanity";

export default function Homeblogs() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] {
            title,
            slug,
            author,
            categories,
            publishedAt,
            body,
            mainImage{
                asset -> {
                    _id,
                    url
                },
                alt
            },
        }`
      )
      .then((data) => setBlogs(data))
      // .then((data) => console.log(data))
      .catch(console.error);
  }, []);

  let latestTwo = blogs.slice(0, 2);

  const renderBlog = (latestTwo, index) => {
    return (
      <Col>
        <Card className="border-0 shadow-sm">
          <Card.Body>
            <Card.Img
              variant="top"
              src={latestTwo && latestTwo.mainImage.asset.url}
            />
            <span className="d-flex mt-2">
              <FaCalendarAlt color="grey" size={18} />
              &nbsp; {latestTwo.publishedAt.slice(0, 10)}
            </span>
            <h2>{latestTwo.title}</h2>
            <Link
              to="/blogs"
              className="btn btn-primary rounded-pill px-4 d-flex align-content-center justify-content-between mt-3"
              style={{ width: "200px" }}
            >
              <span>Read More</span>
              <AiOutlineRight className="mt-1" />
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <div className="homeBlog-main">
      <Container fluid className="text-center">
        <p>Our blog</p>
        <h2>Blog Articles</h2>
      </Container>
      <Container>
        <Row xs={1} md={2} className="g-4">
          {latestTwo.map(renderBlog)}
        </Row>
      </Container>
    </div>
  );
}
