import React from "react";
import ContactBody from "./ContactBody";
import ContactTitle from "./ContactTitle";
import Footer from "./Footer";
import NavbarTop from "./Navbar";
import Donationbanner from './donationbanner'
export default function ContactPage() {
  return (
    <div>
           <Donationbanner/>
      <NavbarTop />
      <ContactTitle />
      <ContactBody />
      <Footer />
    </div>
  );
}
