import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/AboutPage";
import BlogPage from "./components/BlogPage";
import ContactPage from "./components/ContactPage";
import DoctorsPage from "./components/DoctorsPage";
import Gallery from "./components/Gallery";
import Home from "./components/Home";
import Feedback from "./components/Feedback";
import ServicePage from "./components/ServicePage";
import Career from "./components/Career";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="services" element={<ServicePage />} />
        <Route path="doctors" element={<DoctorsPage />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="blogs" element={<BlogPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="career" element={<Career />} />
      </Routes>
    </div>
  );
}

export default App;
