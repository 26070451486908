import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import first from "../assets/gynaecology.jpg";
import second from "../assets/medicine.jpg";
import third from "../assets/orthopaedics.jpg";
import fourth from "../assets/surgery.jpg";
import fifth from "../assets/gastrology.jpg";
import sixth from "../assets/radiology.jpg";
import seventh from "../assets/pathology.jpg";
import eighth from "../assets/physiotherapy.jpg";
import ninth from "../assets/ophthalmology.jpg";
// console.log(first)
export default function ServiceBody() {
  return (
    <div>
      <Container>
        <Row
          xs={1}
          md={2}
          lg={3}
          className="g-4"
          style={{ margin: "100px auto" }}
        >

<Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={eighth} //src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Physiotherapy</Card.Title>
                <Card.Text>
                Physiotherapy is a treatment that reduces the pain , strain and strengthen the muscles and keep the posture perfect.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
 
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={second}  // src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Medicine</Card.Title>
                <Card.Text>
                Medicine is the sciences and art of caring for a patient treating the diagnosis, prognosis, prevention and palliation of disease.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={sixth} // src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Radiology</Card.Title>
                <Card.Text>
                CT SCAN, digital bone densitometry  and x-ray available 24x 7 hours.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={fourth}//  src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Surgery</Card.Title>
                <Card.Text>
                All type of general and laparoscopic surgeries are performed by well experienced surgeon.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={fifth} //   src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">
                  Gastrology
                </Card.Title>
                <Card.Text>
                Gastroenterology OPD services with upper and lower GI endoscopy 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={third}//   src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Orthopaedics</Card.Title>
                <Card.Text>
                All kind of trauma & fracture with spine surgeries and conservative management facility available.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
      

          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={seventh} //src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Pathology</Card.Title>
                <Card.Text>
                Biochemistry, serology, hematology, with advanced computerized analyzers above services available 24x7 hours
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>


          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  
                  src={first}
                  // src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Gynaecology</Card.Title>
                <Card.Text>
                all type of delivery and Gynecological  procedure with infertility counselling andIUI services.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="shadow-lg border-0">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={ninth} //src="https://images.unsplash.com/photo-1666214280391-8ff5bd3c0bf0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8OHx8aG9zcGl0YWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                />
                <Card.Title className="my-3">Ophthalmology</Card.Title>
                <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="serviceBottom">
        <Container>
          <div className="line"></div>
          <h2 className="text-dark fw-bolder">
            We are pleased to offer you the{" "} <br />
            <span style={{color:"#223A66"}}>chance to have the healthy</span>
          </h2>
          <Link
            to="/contact"
            className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
            style={{ width: "fit-content" }}
          >
            <span>GET APPOINTMENT</span>&nbsp;&nbsp;
            <AiOutlineRight className="mt-1" />
          </Link>
        </Container>
      </Container>
    </div>
  );
}
