import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import first from "../assets/1.jpeg";
import second from "../assets/2.jpeg";
import third from "../assets/3.jpeg";
import fourth from "../assets/4.jpeg";
import fifth from "../assets/5.jpeg";
import sixth from "../assets/6.jpeg";
import seventh from "../assets/7.jpeg";
import eighth from "../assets/8.jpeg";
import ninth from "../assets/9.jpeg";
import ten from "../assets/10.jpg";
import eleven from "../assets/11.jpg";
import twelev from "../assets/12.jpg";
// import { AiOutlineRight } from 'react-icons/ai'

export default function Homedocs() {
  return (
    <div className="homeDocs-main">
      <Container fluid className="text-center">
        <p>All Doctors</p>
        <h2>Specialized Doctors</h2>
      </Container>

      <Container>
        <Row xs={2} md={3} lg={4} className="g-4">
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={first} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Rohan</Card.Title>
                <Card.Text>
                  MBBS, DNB(Ortho)
                  <br />
                  Consultant orthopaedic surgeon
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={second} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Parul</Card.Title>
                <Card.Text>
                  MBBS, MD
                  <br />
                  Consultant Pathologist
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={third} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Vimal Shankhdhar</Card.Title>
                <Card.Text>Director</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={fourth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Nisha</Card.Title>
                <Card.Text>
                  MBBS, MD(Med)
                  <br />
                  Consultant Physician
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row xs={2} md={3} lg={4} className="g-4">
        <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={fifth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Neha</Card.Title>
                <Card.Text>
                  BDS (Dental Surgeon)
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={sixth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Surender Gaur</Card.Title>
                <Card.Text>
                  MBBS, PGDCC, FIC
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={seventh} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Suraina Gaur</Card.Title>
                <Card.Text>
                  MBBS, (Medical Officer)
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={eighth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Renu Gaur</Card.Title>
                <Card.Text>
                  Medical Superintendent
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={ninth} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Akash Sharma</Card.Title>
                <Card.Text>
                  MBBS, (Medical Officer)
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img variant="top" src={ten} />
              <Card.Body className="px-0">
                <Card.Title>Dr. Mohd. Junaid Ansar</Card.Title>
                <Card.Text>
                  MBBS, MS, DNB, MNAMS,
                  General, Laparoscopic & Laser Surgeon 
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col><Col>
            <Card className="border-0">
              <Card.Img variant="top" src={eleven} />
              <Card.Body className="px-0">
                <Card.Title>Dr.Ravinder Kumar</Card.Title>
                <Card.Text>
                MBBS, MD, Medicine
                  Fellowship in Clinical Medicine
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col><Col>
            <Card className="border-0">
              <Card.Img variant="top" src={twelev} />
              <Card.Body className="px-0">
                <Card.Title>Dr.Shehla Naseem</Card.Title>
                <Card.Text>
                  MBBS, MD,
                  Anesthesia, Critical Container
                  & Pain Medicine
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
