import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="hero-main">
      <Container>
        <div className="heroContent">
          <div className="line"></div>
          <p className="">TOTAL HEALTH CARE SOLUTION</p>
          <h1>
            YOUR MOST TRUSTED
            <br />
            HEALTH PARTNER
          </h1>
          <p style={{width:"33rem", marginRight:"2rem"}}> 
            RS Gaur Global Multispeciality Hospital Pvt Ltd at Jhajjar is 51
            bed multispeciality hospital aims to provide integrated medical care
            to a wider range of patient population with its state-of-art
            infrastructure and unparalleled medical facilities.
          </p>
          <Link
            to="/about"
            className="btn btn-danger rounded-pill px-4 d-flex align-content-center justify-content-between"
            style={{ width: "200px" }}
          >
            <span>About Us</span>
            <AiOutlineRight className="mt-1" />
          </Link>
        </div>
      </Container>
    </div>
  );
}
