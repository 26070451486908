import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AboutTestimonial from "./AboutTestimonial";

export default function AboutSpecialist() {
  return (
    <div>
      <Container>
        <div className="d-flex flex-column align-items-center mt-5">
          <h2
            style={{ fontSize: "32px", color: "#233B58" }}
            className="fw-semibold"
          >
            Meet Our Specialist
          </h2>
          <div className="line my-4"></div>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
        </div>
      </Container>
      <Container>
        <Row xs={2} md={3} lg={4} className="g-4 mt-4">
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZG9jdG9yfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
              />
              <Card.Body className="px-0">
                <Card.Title>Thomas Henry</Card.Title>
                <Card.Text>Cardiology</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1537368910025-700350fe46c7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8ZG9jdG9yfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
              />
              <Card.Body className="px-0">
                <Card.Title>Thomas Henry</Card.Title>
                <Card.Text>Cardiology</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1666214280465-a40313304801?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8Mjh8fGRvY3RvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
              />
              <Card.Body className="px-0">
                <Card.Title>Thomas Henry</Card.Title>
                <Card.Text>Cardiology</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="border-0">
              <Card.Img
                variant="top"
                src="https://images.unsplash.com/photo-1612349316228-5942a9b489c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fGRvY3RvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
              />
              <Card.Body className="px-0">
                <Card.Title>Thomas Henry</Card.Title>
                <Card.Text>Cardiology</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{marginTop:"100px"}}>
        <Row xs={1} md={2} className="g-4">
            <Col><img src="https://images.unsplash.com/photo-1517120026326-d87759a7b63b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="" style={{maxWidth:"inherit"}} /></Col>
            <Col className="d-flex flex-column justify-content-center">
                <h2>What they say about us</h2>
                <div className="line mb-5"></div>
                <AboutTestimonial/>
            </Col>
        </Row>
      </Container>
    </div>
  );
}
