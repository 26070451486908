import React from 'react'
import Cards from './Cards'
import Footer from './Footer'
import Hero from './Hero'
import Donationbanner from './donationbanner'
import Homeblogs from './Homeblogs'
import Homedocs from './Homedocs'
import NavbarTop from './Navbar'
import PersonalCare from './PersonalCare'

export default function Home() {
  return (
    <div>
         <Donationbanner/>
        <NavbarTop/>
        <Hero/>
        <Cards/>
        <PersonalCare/>
        <Homeblogs/>
        <Homedocs/>
        <Footer/>
    </div>
  )
}
