import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../assets/logo.png";
import { ImLocation } from "react-icons/im";
import { HiMail } from "react-icons/hi";
import { isDesktop, isTablet } from "react-device-detect";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function NavbarTop() {
  return (
    <div>
      <Container
        fluid
        style={{
          backgroundColor: "#213b66",
          display: "flex",
          justifyContent: "space-between",
          color: "#fff",
          padding: "5px",
        }}
      >
        <div className="navEmail">
        <>
            <span> </span>
            <a
              class="text-light"
              href="/"
              style={{
                marginRight: "10px",
                marginLeft: "",
                height: 0,
                color: "#fff;",
              }}
            >
              Home
            </a>
          </>
          <HiMail size={24} />{" "}
          {isDesktop || isTablet ? <span>Email:- </span> : ""}
          <a href="mailto:globalmshospital@gmail.com" className="text-light">
            {isDesktop || isTablet ? "globalmshospital@gmail.com" : "Write Us"}
          </a>
          {isTablet || isDesktop ? (
          <a    className="text-light" href="/contact">
          <span className="ms-5">
              {" "}
              <ImLocation size={20} /> D-2, Shubhash Nagar, Jhajjar Near Civil
              Hospital
            </span></a>  
          ) : (
            ""
          )}
        </div>
        <div className="topCall">
          {isDesktop || isTablet ? <span>Call Now:- </span> : <FiPhoneCall />}{" "}
          <a href="tel:+919518449253" className="text-light"    >
 +91 9518449253
          </a><br/> <a href="tel:+919034224348" className="text-light"  >
          &nbsp; &nbsp; +91 9034224348
          </a>
        </div>
      </Container>
      <Navbar bg="white" expand={false} className="mb-3">
        <Container fluid>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" />
            {isDesktop ? (
              <span>
                RS GAUR GLOBAL
                <br />
                MULTISPECIALITY HOSPITAL
              </span>
            ) : (
              ""
            )}
          </Link>
   <div>
   <a
              href="/feedback"
              className="btn btn-primary"
              style={{ marginRight: "20px", marginLeft: "10px" }}
            >
              Share Your Feedback
            </a>
          <a href="/" className="btn btn-primary " style={{ marginRight: "20px", marginLeft: "10px" }} >
            Download Your Report
          </a>
          <Navbar.Toggle  aria-controls={`offcanvasNavbar-expand-xxl`} />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xxl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
                <Link to="/contact" className="btn btn-primary">
                  Make an Appointment
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link to="/" className="nav-link">
                  Home
                </Link>
                <Link to="/about" className="nav-link">
                  About
                </Link>
                <Link to="/services" className="nav-link">
                  Services
                </Link>
                <Link to="/gallery" className="nav-link">
                  Gallery
                </Link>
                <Link to="/doctors" className="nav-link">
                  Doctors
                </Link>
                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
                <Link to="/career" className="nav-link">
                  Career
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
